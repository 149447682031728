.slider{
    width: 80px;
    height: 400px;
}

.slider-1{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/1.jpg');  /* /public/images/home5.avif' */
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}

.slider-2{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url('/public/images/TBBHSS/2.jpg');  /* /public/images/home6.avi */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-3{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/3.jpg');   /* /public/images/home7.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.slider-4{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/4.jpg');    /* /public/images/home8.avif */
    background-size: cover;
    background-position: center;
}

.slider-5{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/5.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-6{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/6.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-7{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/7.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-8{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/8.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-9{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/9.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-10{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/10.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-11{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/11.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-12{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/12.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-13{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/public/images/TBBHSS/13.jpg');     /* /public/images/home9.avif */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slider-text{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.slider-text h1{
   font-size: 3rem;
   
}

.slider-text h4{
    font-size: 2rem;
    text-align: center;
}