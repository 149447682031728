

body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
  color: rgb(52, 48, 48);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  font-size: 40px;
  background-color: #ff00dd;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 150px 0;
  margin: 0px auto;
  font-family: sans-serif;
  margin: 0 50px;
}

.content {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 10px;
}

.header {
  font-size: 30px;  
}

.img-body img {
  display: flex;
  margin: 0 auto; 
  height: 40vh;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-body {
  width: 300px;
  aspect-ratio: 1/1;
  outline-color: red;
  display: inline-block;
}

.container {
  margin: 0 auto; 
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 40vh; 
  border-color: white;
}

.success-msg{
  width: 100%;
  padding: 14px;
  background-color: #dff0d8;
  color: #468847;
}

.error-msg{
  width: 100%;
  padding: 14px;
  background-color: #f0d0d3;
  color: #df786f;
}





   