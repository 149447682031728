.App {
  text-align: center;  
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.card {
  border: 1px solid  #fff;
  background:black;
  border-radius: 8px;
  overflow: hidden;

  height: 400px;
  color:  #fff;
  cursor: pointer;
}

.card-top h1 {
  font-size: 0.9rem;
  margin: 20px;
}

.card-top > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: black;
}

.card-bottom {
  margin: 10px 20px;
}

.category {
  position: relative;
}
.category::before {
  content: '';
  background: rgb(255, 61, 61);
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  height: 2px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.responsive-map{
  overflow:hidden;
  height: 0;
  padding-bottom: 60%;
  position: relative;
}

.responsive-map iframe{
  border:0;
  height: 100%;
  width:100%;
  left: 0.9rem; 
  top:0.5rem;  
  position: absolute; 
}

.whatsapp_float{
  position:fixed;
  bottom:40px;
  right:20px;
  z-index: 10;
}

.centered{
    display: flex;
    justify-content: center;
    align-items: center;    
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


