
/* Overall container for the contact form */
.contact-rightside {
    /* Take up full width on small screens */
    width: 100%;
  
    /* Take up 7/12ths of the width on medium and large screens */
    @media (min-width: 768px) {
      width: calc(7/12 * 100%);
    }
  
    /* Add some padding for better spacing */
    padding: 20px;
  }
  
  /* Styles for all input fields */
  .contact-input-feild input[type="text"] {
    /* Remove default browser styling */
    appearance: none;
    outline: none;
    
    /* Set border and color properties */
    border: 1px solid #ccc;
    padding: 10px;
    color: #333;
  
    /* Add some spacing between fields */
    margin-bottom: 15px;
  }
  
  /* Style the submit button */
  .btn-style {
    /* Remove default button styles */
    background-color: transparent;
    border: none;
    color: #fff; /* Assuming you want white text */
    padding: 10px 20px;
    font-weight: bold;
  
    /* Add a hover effect (optional) */
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
    /* Assuming you have a specific background color in mind */
    background-color: #007bff;   
    /* Adjust on hover (optional) */
    &:hover {
      background-color: #0062cc;
    }
  }
  
  /* Style the checkbox and label */
  .form-check-label {
    font-size: 12px;
    color: #333;
    line-height: 1.5;
  }

  .contact-leftside img {
    min-width: 100%;
    max-height: 17rem;
    height: auto;
  }

  .space{
    background-color: #282c34;    
    bottom: 0;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}